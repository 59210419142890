@charset "UTF-8";
.animated-router {
  /** START: 自定义进出场动画 **/
  /** END **/
}
.animated-router-container {
  /* 动画容器节点 */
}
.animated-router-in-transition {
  /* 页面动画中 */
  position: relative;
  width: 100%;
  overflow: hidden;
}
.animated-router-forward-appear,
.animated-router-forward-enter {
  transform: translate(100%);
}
.animated-router-forward-appear-active,
.animated-router-forward-enter-active {
  transform: translate(0);
}
.animated-router-forward-exit {
  transform: translate(0);
}
.animated-router-forward-exit-active {
  transform: translate(-100%);
}
.animated-router-backward-appear,
.animated-router-backward-enter {
  transform: translate(-100%);
}
.animated-router-backward-appear-active,
.animated-router-backward-enter-active {
  transform: translate(0);
}
.animated-router-backward-exit {
  transform: translate(0);
}
.animated-router-backward-exit-active {
  transform: translate(100%);
}
.animated-router-forward-appear-active,
.animated-router-forward-enter-active,
.animated-router-forward-exit-active,
.animated-router-backward-appear-active,
.animated-router-backward-enter-active,
.animated-router-backward-exit-active {
  /* 不同过渡阶段需要的过渡时间与缓动效果 */
  transition: transform 0.3s linear;
}
.animated-router-forward-exit,
.animated-router-backward-exit {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
}

.swiper-button-prev {
  left: 80px !important;
  color: var(--text-1-500) !important;
}

.swiper-button-next {
  right: 80px !important;
  color: var(--text-1-500) !important;
}

@media (max-width: 719px) {
  .swiper-button-prev {
    display: none !important;
  }

  .swiper-button-next {
    display: none !important;
  }
}

.swiper-pagination-bullet {
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid var(--text-1-500) !important;
	opacity: 1 !important;
}

.swiper-pagination-bullet-active {
	background: rgba(255, 255, 255, 1) !important;
	border: 1px solid var(--text-1-500) !important;
}