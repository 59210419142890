footer{
    position:relative;
    left: 50%;
    transform: translate(-50%);
    margin: 20px 5% 0px 0%;
    border-top: 1px solid map-get($map:$colors, $key: 'deta');
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    flex-wrap: wrap;
}
.socialLogo{
    opacity: 0.6;
    transition: 0.5s;
}
.socialLogo:hover{
    opacity: 1;
}
.copy{
    font-size: 16px;
    opacity: 0.5;
}
@media (max-width: 310px) {
    footer{
        justify-content: center;
    }
    .footerInner{
        margin-top: 10px;
        justify-content: center;
    }

}