nav{
    margin: auto;
    top: 0px;
    z-index: 1020;
    background-color: transparent;
    
}
.p-nav{
    padding: 20px 0px 6px 4px;
    
}
.mobile{
    position: fixed;
    width: 100vh;
    height: 100vh;
    top: 0px;
    transition: .5s ease;
    display: flex;
    flex-direction: row-reverse;
	@media (min-width: 992px)
	{
		display: none;
	}
}
.darkclose{
    z-index: 10;
    width: 100%;
    height: 100%;
    border: none;
    background-color: map-get($map:$colors, $key: 'mainBlur');
    position: fixed;
    left: 0px;
    top: 0px;
    transition: 0.5s;
    backdrop-filter: blur(5px);
}
.mobileContainer{
    width: 170px;
    height: 100%;
    background-color: var(--main-600);
    z-index: 999999;
    padding: 40px;
    text-align: right;
}
.navLogo{
    z-index: -10;
    width: 30px;
    position: absolute;
    top: 40px;
    left: 40px;
}

.navbar-toggler{
    border: none;
}
.close{
    width: 20px;
    height: 20px;
    border: none;
    background: none;
}
.tras{
    transform: translate(-30%);
    margin-bottom: 20px;
}
.nav-link{
    font-size: 16px;
}
@media (max-width: 1350px) {

    nav{
        margin: 2%;
    }
}