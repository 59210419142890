//--------------------------------------arrow start-----------------------------
$size: 25px;
$speed: 3s;
$peakopacity: 1;
.arrows {
  background: none;
  border: none;
  opacity: 0.5;
  width: $size;
  height: $size;
  @media screen and (max-width: 1270px) {
    transform: translate(-50%, -150%);
  }
  transform: translate(200%, -250%);
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: $size/3 solid map-get($map: $colors, $key: "deta");
    border-bottom: $size/3 solid map-get($map: $colors, $key: "deta");
    transform: translate($size/3, $size * 4/3) rotate(-45deg);
    animation: arrows $speed linear infinite;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: $size/3 solid map-get($map: $colors, $key: "deta");
    border-bottom: $size/3 solid map-get($map: $colors, $key: "deta");
    transform: translate($size * 2/3, 0px) rotate(-45deg);
    animation: arrows $speed linear infinite $speed/-2;
  }
}

@keyframes arrows {
  0% {
    border-left: $size/3 solid rgba(0, 0, 0, 0);
    border-bottom: $size/3 solid rgba(0, 0, 0, 0);
    transform: translate($size/-6, $size * 4/-6) rotate(-45deg);
  }
  10%,
  90% {
    border-left: $size/3 solid rgba(0, 0, 0, 0);
    border-bottom: $size/3 solid rgba(0, 0, 0, 0);
  }
  50% {
    border-left: $size/3 solid map-get($map: $colors, $key: "deta");
    border-bottom: $size/3 solid map-get($map: $colors, $key: "deta");
    transform: translate($size/-6, 0px) rotate(-45deg);
  }
  100% {
    border-left: $size/3 solid rgba(0, 0, 0, 0);
    border-bottom: $size/3 solid rgba(0, 0, 0, 0);
    transform: translate($size/-6, $size * 4/6) rotate(-45deg);
  }
}

.item-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.7s;
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.7s;
}
.item-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.7s;
}
.item-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.7s;
}

.item-enter,
.item-exit {
  position: absolute !important;
}

.reverse-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.7s;
}
.reverse-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.7s;
}
.reverse-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: all 0.7s;
}
.reverse-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.7s;
}

.reverse-enter,
.reverse-exit {
  position: absolute !important;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  .scroll {
    padding: 4vh 0;
    pointer-events: none;
  }
}

html{
	scroll-behavior: auto !important;
  }

.ant-modal-mask {
	background: none !important;
}

//--------------------------------------arrow end-----------------------------
